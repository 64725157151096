import './App.css';

function App() {
  return (
    <section className='index-wrapper'>
      <div className='index-container'>
        <img src='/logo.jpg' alt='Logo Match the Look' />
        <h1>Voltamos já!</h1>
        <p>Estamos a realizar uma atualização para melhorar a sua experiência em Match the Look.</p>
        <span>O site estará temporariamente em modo de manutenção, mas voltaremos brevemente. </span>
        <span>Agradecemos a sua paciência e mal podemos esperar para lhe mostrar as novidades!</span>
      </div>
    </section>
  );
}

export default App;
